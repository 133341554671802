/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

export default class Index extends Component {
  render() {
    return (
      <a href="/admin/users">Users</a>
    );
  }
}
