/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

export default class Login extends Component {
  render() {
    return (
      <li className="nav-item">
        <Router>
          <a className="nav-link" href="/login">Login</a>
        </Router>
      </li>
    );
  }
}
