/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

export default class Status extends Component {
  render() {
    function handleClick(e) {
      e.preventDefault();
      const p = document.getElementById('status').innerHTML;
      if (p === '') {
        const url = `${process.env.REACT_APP_API_URL}status`;
        fetch(url)
          .then((result) => {
            result.text()
              .then((r) => {
                document.getElementById('status').innerHTML = r;
              });
          });
      } else {
        document.getElementById('status').innerHTML = '';
      }
    }
    function handleClickX(e) {
      e.preventDefault();
      const p = document.getElementById('statusX').innerHTML;
      if (p === '') {
        const url = `${process.env.REACT_APP_API_URL}statusX`;
        fetch(url, {
          headers: {
            Authorization: `${localStorage.jwt}`,
          },
        })
          .then((result) => {
            result.text()
              .then((r) => {
                document.getElementById('statusX').innerHTML = r;
              });
          });
      } else {
        document.getElementById('statusX').innerHTML = '';
      }
    }
    return (
      <div className="Status">
        <Button variant="outline-danger" size="sm" onClick={handleClick}>Check Status</Button>
        <div id="status" />
        <Button variant="outline-danger" size="sm" onClick={handleClickX}>Check StatusX</Button>
        <div id="statusX" />
      </div>
    );
  }
}
