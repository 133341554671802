/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().getFullYear(),
    };
  }

  render() {
    return (
      <footer className="Footer">
        brosell
        {' '}
        {this.state.date}
      </footer>
    );
  }
}
