/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../App.css';

export default class LoginPage extends Component {
  render() {
    function submitForm(e) {
      e.preventDefault();
      const email = e.target.elements.email.value;
      const password = e.target.elements.password.value;
      const url = `${process.env.REACT_APP_API_URL}users/tokens`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
      })
        .then((result) => {
          if (result.ok) {
            return result.text();
          }
          return null;
        })
        .then((r) => {
          if (r) {
            localStorage.setItem('jwt', r);
            window.location = '/';
          } else {
            localStorage.removeItem('jwt');
          }
        });
    }
    return (
      <Form onSubmit={submitForm} className="LoginPage">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control required type="email" name="email" placeholder="Enter email" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="password" name="password" placeholder="Password" />
        </Form.Group>
        <Form.Group controlId="formBasicChecbox">
          <Form.Check type="checkbox" label="Remember Me" />
        </Form.Group>
        <Button variant="danger" size="sm" type="submit">
          Submit
        </Button>
      </Form>
    );
  }
}
