/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';

import Body from './Body';
import Error from './Error';
import Footer from './Footer';
import Header from './Header';
import Index from './Admin/Index';
import LoginPage from './Login/LoginPage';
import Users from './Admin/Users';

const auth = {
  isAuthenticated: !!(localStorage.jwt),
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      auth.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to="/login" />
    )}
  />
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Router>
          <Switch>
            <Route path="/" exact component={Body} />
            <PrivateRoute path="/admin" exact component={Index} />
            <PrivateRoute path="/admin/users" exact component={Users} />
            <Route path="/login" exact component={LoginPage} />
            <Route component={Error} />
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
