/* eslint-disable react/jsx-no-bind */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import Admin from 'react-crud-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import Form from 'react-jsonschema-form';
// import "react-crud-admin/css"; //optional css import
import '../App.css';

export default class Example extends Admin {
  constructor() {
    super();
    this.name = 'User'; // name of the objects
    this.name_plural = 'Users'; // name of the objects in plural
    this.list_display_links = ['uuid']; // which property of the object is clickable
    this.list_display = ['uuid', 'email', 'created_at', 'admin']; // a list of properties of the object to displayed on the list display page
  }

  get_actions() {
    return {
      delete: (selected_objects) => {
        const url = `${process.env.REACT_APP_API_URL}users`;
        selected_objects.forEach((user) => {
          fetch(url, {
            method: 'delete',
            headers: {
              Authorization: `${localStorage.jwt}`,
            },
            body: JSON.stringify({
              email: user.email,
            }),
          }).then(() => {
            window.location.reload();
          });
        });
      },
    };
  }

  get_queryset() {
    const url = `${process.env.REACT_APP_API_URL}users`;
    fetch(url, {
      method: 'get',
      headers: {
        Authorization: `${localStorage.jwt}`,
      },
    }).then((response) => {
      response.text()
        .then((r) => {
          this.set_queryset(JSON.parse(r));
        });
    });
  }

  get_form(object = null) {
    const schema = {
      title: this.name,
      type: 'object',
      required: ['name', 'email', 'password'],
      properties: {
        name: { type: 'string', title: 'Name', default: '' },
        email: { type: 'string', title: 'Email', default: '' },
        password: { type: 'string', title: 'Password', default: '' },
      },
    };

    if (!object) {
      return <Form schema={schema} onSubmit={this.form_submit.bind(this)} />;
    }
    return <Form schema={schema} formData={object} onSubmit={this.form_submit.bind(this)} />;
  }

  form_submit(form) {
    const user = form.formData;
    if (form.edit) {
      alert('user updates not implemented yet');
      this.response_change();
    } else {
      const url = `${process.env.REACT_APP_API_URL}users`;
      fetch(url, {
        method: 'post',
        body: JSON.stringify({
          name: user.name,
          email: user.email,
          password: user.password,
        }),
      }).then((response) => {
        response.text().then(() => {
          this.response_add();
        });
      });
    }
  }
}
