/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Status from './Status';

export default class Body extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h1 className="mt-5">Brosell Starter Template</h1>
            <p className="lead">Words Words Words</p>
            <ul className="list-unstyled">
              <li><Status /></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
